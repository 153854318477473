import React, { useState, useEffect, 
  // useCallback 
} from "react";
import axios from "axios";
import { connect } from "react-redux";
import editlocation from "../../assets/images/edit-location.svg";
import MyAccountTab from "./MyAccountTab";
import AddIcon from '@mui/icons-material/Add';
import { useRef } from 'react';
import {
  saveUserAddressStart,
  fetchUserAddressStart,
  deleteUserAddressStart,
  updateUserAddressStart,
} from "../../store/actions/UserAction";

const initialState = {
  fullName: "",
  mobile_Number: "",
  pincode: "",
  address: "",
  town: "",
  district: "",
  state: "",
};

const ManageAddress = (props) => {
  const { isTracking = false } = props;
  const [
    { fullName, mobile_Number, pincode, address, town, district, state },
    setAddressData,
  ] = useState(initialState);
  const [addAddress, setAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [addId, setAddId] = useState("");
  const contactDetailsRef = useRef(null);

  const handleAddressChange = (event) => {
    const { name, value } = event.currentTarget;
    setAddressData((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearForm = () => {
    setAddressData({ ...initialState });
  };

  const handleAddressSubmit = (event) => {
    event && event.preventDefault();
    if (!editAddress) {
      props.dispatch(
        saveUserAddressStart({
          fullName: fullName,
          mobile_Number: mobile_Number,
          pincode: pincode,
          address: address,
          town: town,
          district: district,
          state: state,
        })
      );
    } else {
      props.dispatch(
        updateUserAddressStart({
          fullName: fullName,
          mobile_Number: mobile_Number.toString(),
          pincode: pincode.toString(),
          address: address,
          town: town,
          district: district,
          state: state,
          addressId: addId,
        })
      );
    }
    clearForm();
  };

  const handleDeleteAddress = (addressId) => {
    props.dispatch(
      deleteUserAddressStart({
        addressId,
      })
    );
  };


  // const handleDeleteAddress = useCallback(
  //   (addressId) => {
  //     console.log("Deleting address:", addressId);
  //     props.dispatch(deleteUserAddressStart({ addressId }));
  //   },
  //   [props.dispatch]
  // );
  const handleEditAddress = (address) => {
    setAddAddress(true);
    setAddId(address._id);
    setAddressData({
      fullName: address.fullName,
      mobile_Number: address.mobile_Number,
      pincode: address.pincode,
      address: address.address,
      town: address.town,
      district: address.district,
      state: address.state,
    });
  };

  useEffect(() => {
    props.dispatch(
      fetchUserAddressStart({ userId: sessionStorage.getItem("user_id") })
    );
  }, [
    props.deleteUserAddress.loading ||
    props.saveAddress.loading ||
    props.updateUserAddress.loading,
  ]);

  return (
    <div className="my-account page-pdding">
      <div className="top-account-bar text-center">
        <h4>My Account</h4>
      </div>
      <div className="product-listing">
        {!isTracking && <MyAccountTab />}
        <div className="product-content">
          <div className="account-header">
            <div className="order-left">
              <img src={editlocation} alt="" />
              <h2> Manage Addresses </h2>
            </div>
          </div>
          {props.userAdd.loading
            ? "Loading..."
            : (props.userAdd.data.data || []).map((address) => (
              <div key={address._id} className="order-row-wrap">
                <div className="order-header row">
                  <div className="col-md-4 p-0">
                    <h4>{address.fullName}</h4>
                  </div>
                  <div className="col-md-4 p-0">
                    <h4>Mobile Number - {address.mobile_Number}</h4>
                  </div>
                </div>
                <div className="order-body-wrap">
                  <div className="shipping-text p-0">
                    <h4>
                      {address.address +
                        ", " +
                        address.town +
                        ", " +
                        address.state +
                        ", " +
                        address.pincode}
                    </h4>
                    <div className="d-flex align-items-center">
                      <a
                        href="#"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   e.stopPropagation();
                        //   handleDeleteAddress(address._id);
                        // }}
                        onClick={() => handleDeleteAddress(address._id)}
                        className="nxt-btn ml-3 mr-2"
                      >
                        Remove
                      </a>
                      <a
                        href="#"
                        onClick={() => {
                          handleEditAddress(address);
                          setEditAddress(true);
                          // window.scrollTo(0, 0);
                          contactDetailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                          // contactDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
                        }}
                        className="nxt-btn"
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {!addAddress && (
            <div className="add-address">
              <a
                href="#"
                onClick={() => setAddAddress(true)}
                className="new-add"
              >
                Add New Address <AddIcon></AddIcon>
              </a>
            </div>
          )}
          {addAddress && (
            <div className="form-address-wrapper">
              <form
                onSubmit={handleAddressSubmit}
                className="enquirey-form px-0"
              >
                <div className="row" ref={contactDetailsRef} >
                  <div className="col-md-12">
                    <h5>Contact Details</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Full Name"
                        name="fullName"
                        required
                        onChange={handleAddressChange}
                        value={fullName}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        placeholder="Mobile Number"
                        name="mobile_Number"
                        maxLength="10"
                        pattern="\d{10}"
                        title="Please enter exactly 10 digits"
                        required
                        onChange={handleAddressChange}
                        value={mobile_Number}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Address"
                        name="address"
                        required
                        onChange={handleAddressChange}
                        value={address}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="locality"
                        placeholder="Locality / Town"
                        name="town"
                        required
                        onChange={handleAddressChange}
                        value={town}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="pincode"
                        placeholder="Pincode"
                        pattern="^[1-9][0-9]{5}$"
                        title="Please enter six digit pin code and not starting with 0"
                        name="pincode"
                        required
                        onChange={handleAddressChange}
                        onBlur={async (event) => {
                          const res = await fetch(`https://api.postalpincode.in/pincode/${event.currentTarget.value}`)
                            .then((res) => res.json());
                          if (res[0].Status === "Success") {
                            setAddressData((prevState) => ({
                              ...prevState,
                              pincode: res[0].PostOffice[0].Pincode,
                              district: res[0].PostOffice[0].District,
                              state: res[0].PostOffice[0].State,
                            }));
                          }
                        }}
                        value={pincode}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="state"
                        placeholder="State"
                        name="state"
                        required
                        onChange={handleAddressChange}
                        value={state}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="District"
                        name="district"
                        required
                        onChange={handleAddressChange}
                        value={district}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-primary custom-border-btn"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  saveAddress: state.users.saveUserAddress,
  userAdd: state.users.userAddress,
  deleteUserAddress: state.users.deleteUserAddress,
  updateUserAddress: state.users.updateUserAddress,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ManageAddress);
