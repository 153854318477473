import {
    FETCH_HOME_PAGE_START,
    FETCH_HOME_PAGE_SUCCESS,
    FETCH_HOME_PAGE_FAILURE,
    FETCH_BRAND_NAME,
} from '../actions/ActionConstants';

// fetch home page data
export function fetchHomePageStart(data) {
    return {
      type: FETCH_HOME_PAGE_START,
      data,
    };
}
export function fetchBrandName(data){    
    return{
        type: FETCH_BRAND_NAME,
        data,
    }
}
  
export function fetchHomePageSuccess(data) {
    return {
        type: FETCH_HOME_PAGE_SUCCESS,
        data,
    };
}
  
export function fetchHomePageFailure(data) {
    return {
      type: FETCH_HOME_PAGE_FAILURE,
      data,
    };
}