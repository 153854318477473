// all constants will be added

// Registration
export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

// Login
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// Store Locator
export const STORE_LOCATOR_START = "STORE_LOCATOR_START";
export const STORE_LOCATOR_SUCCESS = "STORE_LOCATOR_SUCCESS";
export const STORE_LOCATOR_FAILURE = "STORE_LOCATOR_FAILURE";

// Contact Us
export const CONTACT_US_START = "CONTACT_US_START";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";

// Product
export const FETCH_PRODUCT_START = "FETCH_PRODUCT_START";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";

export const FETCH_PRODUCT_SINGLE_START = "FETCH_PRODUCT_SINGLE_START";
export const FETCH_PRODUCT_SINGLE_SUCCESS = "FETCH_PRODUCT_SINGLE_SUCCESS";
export const FETCH_PRODUCT_SINGLE_FAILURE = "FETCH_PRODUCT_SINGLE_FAILURE";

export const PRODUCT_FILTER_START = "PRODUCT_FILTER_START";
export const PRODUCT_FILTER_SUCCESS = "PRODUCT_FILTER_SUCCESS";
export const PRODUCT_FILTER_FAILURE = "PRODUCT_FILTER_FAILURE";


// save user address
export const SAVE_USER_ADDRESS_START = "SAVE_USER_ADDRESS_START";
export const SAVE_USER_ADDRESS_SUCCESS = "SAVE_USER_ADDRESS_SUCCESS";
export const SAVE_USER_ADDRESS_FAILURE = "SAVE_USER_ADDRESS_FAILURE";

// get user address
export const FETCH_USER_ADDRESS_START = "FETCH_USER_ADDRESS_START";
export const FETCH_USER_ADDRESS_SUCCESS = "FETCH_USER_ADDRESS_SUCCESS";
export const FETCH_USER_ADDRESS_FAILURE = "FETCH_USER_ADDRESS_FAILURE";

// delete user address
export const DELETE_USER_ADDRESS_START = "DELETE_USER_ADDRESS_START";
export const DELETE_USER_ADDRESS_SUCCESS = "DELETE_USER_ADDRESS_SUCCESS";
export const DELETE_USER_ADDRESS_FAILURE = "DELETE_USER_ADDRESS_FAILURE";

// update user address
export const UPDATE_USER_ADDRESS_START = "UPDATE_USER_ADDRESS_START";
export const UPDATE_USER_ADDRESS_SUCCESS = "UPDATE_USER_ADDRESS_SUCCESS";
export const UPDATE_USER_ADDRESS_FAILURE = "UPDATE_USER_ADDRESS_FAILURE";


// get home page data
export const FETCH_HOME_PAGE_START = "FETCH_HOME_PAGE_START";
export const FETCH_HOME_PAGE_SUCCESS = "FETCH_HOME_PAGE_SUCCESS";
export const FETCH_HOME_PAGE_FAILURE = "FETCH_HOME_PAGE_FAILURE";
export const FETCH_BRAND_NAME = "FETCH_BRAND_NAME";

// get user profile data
export const FETCH_USER_PROFILE_START = "FETCH_USER_PROFILE_START";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";

// social login
export const SOCIAL_LOGIN_START = "SOCIAL_LOGIN_START";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAILURE = "SOCIAL_LOGIN_FAILURE";

// career
export const SAVE_CAREER_START = "SAVE_CAREER_START";
export const SAVE_CAREER_SUCCESS = "SAVE_CAREER_SUCCESS";
export const SAVE_CAREER_FAILURE = "SAVE_CAREER_FAILURE";

// cart Actions
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const EMPTY_CART = 'EMPTY_CART';
