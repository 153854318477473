import { all, takeLatest, select, put } from "redux-saga/effects";
import ReactGA from "react-ga";
import {
  REGISTER_START,
  LOGIN_START,
  SAVE_USER_ADDRESS_START,
  FETCH_USER_ADDRESS_START,
  DELETE_USER_ADDRESS_START,
  UPDATE_USER_ADDRESS_START,
  FETCH_USER_PROFILE_START,
  SOCIAL_LOGIN_START,
} from "../actions/ActionConstants";
import ApiMethods from "../../utils/ApiMethods";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  userRegistrationSuccess,
  userRegistrationFailure,
  userLoginSuccess,
  userLoginFailure,
  saveUserAddressSuccess,
  saveUserAddressFailure,
  fetchUserAddressSuccess,
  fetchUserAddressFailure,
  deleteUserAddressSuccess,
  deleteUserAddressFailure,
  updateUserAddressSucess,
  updateUserAddressFailure,
  fetchUserProfileSuccess,
  fetchUserProfileFailure,
  socialLoginSuccess,
  socialLoginFailure,
} from "../actions/UserAction";
import appendSpreadsheet from "../../components/helper/appendSpreadsheet";

// Registration API

function* userRegisterAPI() {
  try {
    const userData = yield select(
      (state) => state.users.registerInputData.inputData
    );
    const response = yield ApiMethods.postMethod(
      "user/register",
      userData
    );
    if (response.data.success) {
      ReactGA.event({category: 'USER_REGISTRATION', action: 'REGISTER', label: 'REGISTER_SUCCESS'});
      yield put(userRegistrationSuccess(response.data));
      sessionStorage.setItem("access", response.data.token);
      sessionStorage.setItem("refresh_token", response.data.refreshToken);
      sessionStorage.setItem("user_id", response.data.user._id);
      const fullName = response.data.user.firstName + " " + response.data.user.LastName;
      sessionStorage.setItem("name", fullName);
      sessionStorage.setItem("email", response.data.user.email);
      appendSpreadsheet({
        email: response.data.user.email,
        event: "USER_REGISTRATION",
        createdAt: new Date().toString(),
        productId: ''
      });
      const notificationMessage = getSuccessNotificationMessage(
        "Registration Success"
      );
      yield put(createNotification(notificationMessage));
      window.location.reload();
    } else {
      yield put(userRegistrationFailure(response.data));
      
      const notificationMessage = getErrorNotificationMessage(response.data.messgae);
      // const notificationMessage = getErrorNotificationMessage(response.data.messgae);
      yield put(createNotification(notificationMessage));
    }
  } catch {}
}

// Login API

function* userLoginAPI() {
  try {
    const userData = yield select((state) => state.users.loginInputData.inputData);
    const response = yield ApiMethods.postMethod("user/login", userData);

    if (response.data.success) {
      ReactGA.event({category: 'USER_LOGIN', action: 'LOGIN', label: 'LOGIN_SUCCESS'});
      yield put(userLoginSuccess(response.data));
      sessionStorage.setItem("access", response.data.token);
      sessionStorage.setItem("refresh_token", response.data.refreshToken);
      sessionStorage.setItem("user_id", response.data.user._id);
      const fullName = response.data.user.firstName + " " + response.data.user.LastName;
      sessionStorage.setItem("name", fullName);
      sessionStorage.setItem("email", response.data.user.email);
      yield put(appendSpreadsheet({
        email: response.data.user.email,
        event: "USER_LOGIN",
        createdAt: new Date().toString(),
        productId: ''
      }));
      if (response.data.user?.memberShip.length > 0) {
        sessionStorage.setItem('isMembership', true);
      }
      if (response.data.user?.loyalityPoint) {
        sessionStorage.setItem('loyalityPoint', response.data.user?.loyalityPoint);
      }
      const notificationMessage =
        getSuccessNotificationMessage("Login Success");
      yield put(createNotification(notificationMessage));
      window.location.reload();
    } else {
      yield put(userLoginFailure(response.message));
      const notificationMessage = getErrorNotificationMessage(response.data.message);
      yield put(createNotification(notificationMessage));
    }
  } catch {}
}

// save address API
function* saveUserAddressAPI() {
  try {
    const addData = yield select(
      (state) => state.users.saveUserAddress.inputData
    );
    const response = yield ApiMethods.postMethod("user/add/address", addData);

    if (response.data.success) {
      yield put(saveUserAddressSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.messgae
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(saveUserAddressFailure(response.data));

      const notificationMessage = getErrorNotificationMessage(
        response.data?.errors.mobile_Number[0]
      );

      yield put(createNotification(notificationMessage));

      // const errorMessages = Object.values(response.data?.errors).flat() || [];
      // errorMessages.forEach((error) => {
      //   const notificationMessage = getErrorNotificationMessage(error);
      //   yield put(createNotification(notificationMessage));
      // });
     
      // Object.values(response.data?.errors).flat()?.forEach((error) => {
      //   const notificationMessage = getErrorNotificationMessage(
      //     error
      //   );
      //   // yield put(createNotification(notificationMessage));
      //   createNotification(notificationMessage);
      // })
    }
  } catch {}
}

// fetch user address
function* fetchUserAddressAPI() {
  try {
    const addressData = yield select(
      (state) => state.users.userAddress.inputData
    );
    const response = yield ApiMethods.getMethod(`user/getAddress/${addressData.userId}`);

    if (response.data.success) {
      yield put(fetchUserAddressSuccess(response.data));
    } else {
      yield put(fetchUserAddressFailure(response.data));
      const notificationMessage = getErrorNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    }
  } catch {}
}

// delete user address

function* deleteUserAddressAPI() {
  try {
    const addressData = yield select(
      (state) => state.users.deleteUserAddress.inputData
    );
    const response = yield ApiMethods.getMethod(`user/delete/address/${addressData.addressId}`);

    if (response.data.success) {
      yield put(deleteUserAddressSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.messgae
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(deleteUserAddressFailure(response.data));
      const notificationMessage = getErrorNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    }
  } catch {}
}

function* updateUserAddressAPI() {
  try {
    const addressData = yield select(
      (state) => state.users.updateUserAddress.inputData
    );
    const response = yield ApiMethods.postMethod('user/edit/address', addressData);

    if (response.data.success) {
      yield put(updateUserAddressSucess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        'Address Updated'
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(updateUserAddressFailure(response.data));
      const notificationMessage = getErrorNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    }
  } catch {}
}

function* fetchUserProfileAPI() {
  try {
    const profileData = yield select(
      (state) => state.users.userProfile.inputData
    );
    const response = yield ApiMethods.getMethod("user/detail", profileData);
  

    if (response.data.success) {
      yield put(fetchUserProfileSuccess(response.data.data));
    } else {
      yield put(fetchUserProfileFailure(response.data));
      const notificationMessage = getErrorNotificationMessage(
        'Something went wrong'
      );
      yield put(createNotification(notificationMessage));
    }
  } catch {}
}

function* socialLoginAPI() {
  try {
    const socialData = yield select(
      (state) => state.users.socialData.inputData
    );
    const response = yield ApiMethods.postMethod("user/socialLogin", socialData);
    if (response.data.success) {

      yield put(socialLoginSuccess(response.data));
      sessionStorage.setItem("access", response.data.token);
      sessionStorage.setItem("refresh_token", response.data.refreshToken);
      sessionStorage.setItem("user_id", response.data.user._id);
      const fullName = response.data.user.firstName + " " + (response.data.user.LastName || '');
      sessionStorage.setItem("name", fullName);
      sessionStorage.setItem("email", response.data.user.email);
      const notificationMessage =
        getSuccessNotificationMessage("Login Success");
      yield put(createNotification(notificationMessage));
      window.location.reload();
    } else {
      yield put(socialLoginFailure(response.data));
      const notificationMessage = getErrorNotificationMessage(response.data.messgae);
      yield put(createNotification(notificationMessage));
    }
  } catch {}
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(REGISTER_START, userRegisterAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(SAVE_USER_ADDRESS_START, saveUserAddressAPI),
    yield takeLatest(FETCH_USER_ADDRESS_START, fetchUserAddressAPI),
    yield takeLatest(DELETE_USER_ADDRESS_START, deleteUserAddressAPI),
    // yield takeLatest(DELETE_USER_ADDRESS_START, deleteUserAddressAPI),
    yield takeLatest(UPDATE_USER_ADDRESS_START, updateUserAddressAPI),
    yield takeLatest(FETCH_USER_PROFILE_START, fetchUserProfileAPI),
    yield takeLatest(SOCIAL_LOGIN_START, socialLoginAPI),
  ]);
}
