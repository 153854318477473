import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import image from '../../assets/images/amit.png'
import image from '../../assets/images/chasma.png'
import second from '../../assets/images/second.png'
import third from '../../assets/images/third.png'
import fourth from '../../assets/images/fourth.png'
import BoltIcon from '@mui/icons-material/Bolt';
import StarIcon from '@mui/icons-material/Star';
import Slider from "react-slick";


const sliderProps = {
  arrows: false,
  infinite: true,
  centerMode: true,
  className: 'client-slider',
  speed: 500,
  loop: true,
  autoplay: true,
  slidesToShow: 3,
  fade: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const cardData = [
  {
    title: "Lizard 1",
    image: image,
    description: "Another description for the second lizard.",
    discountText: "New"
  },
  {
    title: "Lizard 2",
    image: second,
    description: "Another description for the second lizard.",
    discountText: "New"
  },
  {
    title: "Lizard 2",
    image: third,
    description: "Another description for the second lizard.",
    discountText: ""
  },
  {
    title: "Lizard 2",
    image: fourth,
    description: "Another description for the second lizard."
  },
  // Add more objects for additional cards as needed
];

export default function MediaCard() {
  return (
    <>
      <Slider {...sliderProps}>
        {cardData.map((card, index) => {          
          return (
            <Card key={index} sx={{ maxWidth: 345 }} className='ns-custom-card' >
              {card.discountText ? <span className="new"><BoltIcon className='bolt'/>{card.discountText}</span> : null}
                <CardMedia
                    sx={{ height: 140 }}
                    image={card.image}
                    title={card.title}
                />
                <CardContent style={{textAlign:'center'}}>
                    <Typography >
                        <StarIcon className='icon-clr'/>
                        <StarIcon className='icon-clr'/>
                        <StarIcon className='icon-clr'/>
                        <StarIcon className='icon-clr'/>
                        <StarIcon className='icon-clr'/>
                    </Typography>
                    
                    <Typography className='mb-0 p-font mt-2' gutterBottom variant="h6" component="p">
                        {/* Tedwoods Square  Transparent Tortoise */}
                    {card.title}
                    </Typography>
                 
                    <Typography variant="body2" color="text.secondary">
                    {card.description}
                    </Typography>
                </CardContent>
                <CardActions className='buy-btn'>
                    <Button  className='buy-btn1'>Buy Now</Button>
                    <p className='mb-0 buy-price'>$2499</p>
                </CardActions>
            </Card>
          );
        })}
      </Slider>
    </>
  );
}

