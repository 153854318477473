import {
    FETCH_HOME_PAGE_START,
    FETCH_HOME_PAGE_SUCCESS,
    FETCH_HOME_PAGE_FAILURE,
    FETCH_BRAND_NAME,
} from '../actions/ActionConstants';


const initialState = {
    homeData: {
        data: {},
        inputData: {},
        loading: true,
        error: false,
     
    },
    brand: ''
}

const HomeReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_HOME_PAGE_START:
            return {
                ...state,
                homeData: {
                    data: {},
                    loading: true,
                    error: false
                }
            };
        case FETCH_BRAND_NAME:            
            return{
                ...state,
                brand: action.data
            }    
        case FETCH_HOME_PAGE_SUCCESS:
            return {
                ...state,
                homeData: {
                    data: action.data,
                    loading: false,
                    error: false
                }
            };
        case FETCH_HOME_PAGE_FAILURE:
            return {
                ...state,
                homeData: {
                    data: {},
                    loading: false,
                    error: action.data
                }
            };
        default:
            return state;
    }
};

export default HomeReducer;